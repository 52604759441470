import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sketch from "react-p5";
import '../../scss/Camera.scss';
import { setImageSet, processImage } from '../Model';
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as bodyPix from "@tensorflow-models/body-pix";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import vert from '../../assets/webcam.vert';
import pxfrag from '../../assets/pixelandbgmask.frag';
import bgfrag from '../../assets/bgmask.frag';
// import { pad4d } from '@tensorflow/tfjs-core';
import Countdown from '../Countdown';

// const BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const AWS_URL = process.env.REACT_APP_AWS_URL;
const AWS_ID = process.env.REACT_APP_AWS_ID;
const AWS_SECRET = process.env.REACT_APP_AWS_SECRET;

let capture;
const camw = 320;
const camh = 240;
let theShader;
// let imageOptions = [];
let bpn;
let imgdata;
let maskimg;

let preventMulti = true;

export default function Camera({settings}){
    const navigate = useNavigate();

    // const canvasRef = useRef(null);
    // const webcamRef = useRef(null);
    const bgRef = useRef(null);
    const fgRef = useRef(null);
    const previewRef = useRef(null);

    // const [ options, setOptions ] = useState([]);
    const [ counting, setCounting ] = useState(-1);
    const [ selectedOption, setSelectedOption ] = useState(0);
    const [ isVertical, setIsVertical ] = useState(false);

    const optionType = settings.options.length>0 ? settings.options[0].type : '';

    

    const preload = (p5) => {
        const frag = settings.filterProcess==='backend' ? bgfrag : pxfrag;
        // if we are processing on backend, only do bg masking.
        theShader = p5.loadShader(vert,frag);

    };
    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
		// (without that p5 will render the canvas outside of your component)
		p5.createCanvas(camw, camh, p5.WEBGL).parent(canvasParentRef);
		// p5.createCanvas(camw, camh).parent(canvasParentRef);
        capture = p5.createCapture(p5.VIDEO); 
        capture.size(camw,camh);
        capture.hide();
        console.log('p5 setup');

        maskimg = p5.createImage(320,240);

        capture.elt.onloadeddata = handleVideoReady;
    };
    const draw = (p5) => {

        // ensure no webgl update if no capture.
        if (!(capture && capture.elt && capture.elt.srcObject && capture.elt.srcObject.active)) return;

        p5.shader(theShader);
        theShader.setUniform('frame',capture);

        // mask processing
        if (imgdata){
            
            maskimg.loadPixels();
            //maskimg.pixels = JSON.parse(JSON.stringify(imgdata));
            for (var i=3;i<imgdata.length;i+=4){
                maskimg.pixels[i] = imgdata[i];
            }
            //maskimg.pixels = imgdata;
            maskimg.updatePixels();
            // p5.image(maskimg,0,0,camw,camh);
            theShader.setUniform('mask',maskimg);
        }
        
        p5.rect(0,0,camw,camh);
    }

    function handleVideoReady(){
        console.log('video ready', bpn, settings.removeBackground);
        // initMasking(capture.elt);
        
        // check aspect ratio of camera:
        const settngs = capture.elt.srcObject.getTracks()[0].getSettings();
        const aspect = settngs.width/settngs.height; // settngs.aspectRatio; <- ios does not report aspectRatio.
        //console.log(settngs);
        //alert(JSON.stringify(settngs));
        if (aspect<1) {
            console.log('?');
            setIsVertical(true);
        }
        if (settings.removeBackground){
            if (bpn) {
                initMasking(capture.elt);
            }
        }
    }

    function handleTakePic(){
        preventMulti = true;
        setCounting(1);
    }
    function handleCountdownComplete(){
        console.log('take pic',preventMulti);
        if (!preventMulti) return; // second time getting called? only seems to happen when background is on.
        preventMulti = false;
        setCounting(0); // this triggers processing mode.
        // const imageSrc = webcamRef.current.getScreenshot();
        
        const imageSrc = document.getElementById('defaultCanvas0').toDataURL('image/jpg');

        // get before image
        const beforeCanv = document.createElement('canvas');
        beforeCanv.width = isVertical?'768':'1024'; 
        beforeCanv.height = isVertical?'1024':'768'; 
        const beforeCtx = beforeCanv.getContext('2d');
        beforeCtx.drawImage(capture.elt,0,0,beforeCanv.width,beforeCanv.height);
        const beforeImgSrc = beforeCanv.toDataURL('image/jpg');

        // stop the camera.
        if (capture && capture.elt && capture.elt.srcObject){
            const tracks = capture.elt.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
        if (capture && capture.stop) capture.stop();
        if (window.p5 && window.p5.remove) window.p5.remove();
        
        if (settings.filterProcess==="backend") {
            // send the before image to backend for processing.
            const fetchData = async () => {
                const data = await processImage(beforeImgSrc);
                if (data.status===500){
                    // catch error.
                    window.alert('There was an error processing your request, please try again!');
                    navigate('/');
                } else {
                    navigate('/review');
                }
            }

            // call the function
            fetchData()
            // make sure to catch any error
            .catch(error=>{
                console.log(error);
                window.alert('There was an error processing your request, please try again!');
                navigate('/');
            });

            return;
        }

        // freeze image.
        const img = new Image();
        img.onload = async function(){
            const tempCtx = previewRef.current.getContext("2d");

            tempCtx.drawImage(bgRef.current,0,0,1024,1366);
            // current canvas is 960x720.
            const tw = 1366 * 960/720;
            const tx = (1024 - tw)/2;
            tempCtx.drawImage(img,tx,0,tw,1366);
        //     canvasRef.current.getContext('2d').drawImage(img,0,0,canvasRef.current.width,canvasRef.current.height);
            tempCtx.drawImage(fgRef.current,0,0,1024,1366);

            const finalImgSrc = previewRef.current.toDataURL('image/jpg');
            
            let key = new Date();
            key = key.valueOf();

            if (settings.skipUpload){
                // await setImageSet(beforeImgSrc,finalImgSrc);
            } else {
                const before = await uploadImage(beforeImgSrc,key,0);
                const after = await uploadImage(finalImgSrc,key,1);
                console.log('heyo!',before,after);
                await setImageSet(before,after);
            }
            
            navigate('/review');
        }
        img.src = imageSrc;

    }
    async function uploadImage(imgdatauri,key,index){
        const blob = dataURItoBlob(imgdatauri);
        const folder = index===0 ? 'before' : 'after';
        
        const client = new S3Client({
            bucketEndpoint:AWS_URL,
            region:REGION,
            credentials:{
                secretAccessKey:AWS_SECRET,
                accessKeyId:AWS_ID
            }
        });
        const command = new PutObjectCommand({
            Bucket:AWS_URL,
            ACL:'public-read',
            Key: folder+'/'+key+'.jpg',
            Body:blob
        });
        await client.send(command);
        return 'https://'+AWS_URL+'/'+folder+'/'+key+'.jpg';
    }

    function handleOptionClick(e){
        console.log('option',e.target.getAttribute('data-value'),e.target.getAttribute('data-index'));
        setOption(e.target.getAttribute('data-value'));
        setSelectedOption(Number(e.target.getAttribute('data-index')));
        
    }
    function setOption(val){
        // const webcam = capture.elt;
        //     // const canvas = canvasRef.current;
        //     // const context = canvas.getContext("2d");
        //     initMasking(webcam);
        // return; 

        if (optionType==='foregroundImage,backgroundImage') {
            const paths = val.split(',');
            fgRef.current.src = paths[0];
            bgRef.current.src = paths[1];
        } else if (optionType==='backgroundImage'){
            bgRef.current.src = val;
        }
    }

    useEffect(()=>{
        if (settings.options && settings.options.length>0) {
            // set up default options.
            setOption(settings.options[0].value);
        }
        if (settings.removeBackground){
            if (bpn===undefined){
                bodyPix.load().then((net) => {
                    console.log('bodypixnet loaded');
                    //setBodypixnet(net);
        
                    bpn = net;
                });
            } else {
                console.log('bodypixnet already loaded');
            }
        }

        return () =>{
        //     console.log('unmount');

            if (capture && capture.elt && capture.elt.srcObject){
                const tracks = capture.elt.srcObject.getTracks();
                tracks.forEach(track => track.stop());
            }
            if (capture && capture.stop) capture.stop();
            if (window.p5 && window.p5.remove) window.p5.remove();
        //     // clearInterval(timer);
        }
    },[]);

    const initMasking = async ( webcam ) => {
        console.log(bpn);
        const segmentation = await bpn.segmentPerson(webcam);
        imgdata = bodyPix.toMask(segmentation).data;

        (async function drawMask() {
            // console.log('drawMask',webcam);
            if (!webcam.srcObject.active) return;
            requestAnimationFrame(drawMask);
            const segmentation = await bpn.segmentPerson(webcam);
            imgdata = bodyPix.toMask(segmentation).data;
        })();
    };

    return (
        <div className={`fullscreen camera ${isVertical?'vert':''}`}>
            <img ref={bgRef} className="canvas-background" alt="background" />
            <Sketch preload={preload} setup={setup} draw={draw} />
            <img ref={fgRef} className="canvas-foreground" alt="foreground" />
            {counting>0 && <Countdown onComplete={handleCountdownComplete} />}
            {counting<0 && 
                <div className="options-wrapper">
                    {settings.options.map((option,index)=>
                        <button data-value={option.value} data-type={option.type} onClick={handleOptionClick} key={index} data-index={index} className={selectedOption===index?'selected':''}>{option.label}</button>
                    )}
                </div>}
            {counting<0 && 
                <button className="take-pic" onClick={handleTakePic}>{settings.cta}</button>}
            {counting===0 &&
                <canvas ref={previewRef} className="previewCanvas" width="1024" height="1366" />}
            {counting===0 &&
                <div className="processing-msg">{settings.processingMsg}</div>}
        </div>
    );
}

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}