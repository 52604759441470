import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import { Splash, Welcome, Camera, Preview, Signup } from './components/pages';
import Question from './components/Question';
import { useLocation } from 'react-router-dom';
import './App.scss';
import { init } from './components/Model';
// import { render } from '@testing-library/react';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const settings = require('./config.json');

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      logoURL:'',
      inlineStyles:{},
    }
  }

  componentDidMount(){

    const apiPath = BACKEND_URL; //'https://selfie.astoundlab.com/api'; //'/data';
    const apiPostfix = ''; //'.json';
    // const siteid = 'ASTOUND';
    init(apiPath,apiPostfix);
    this.setState(settings);

    // load external style sheet.
    if (settings.styles && settings.styles.external) {
      if ( document.getElementById('externalStyles') ) return;
      const csstag = document.createElement('link');
      csstag.rel = "stylesheet";
      csstag.id = 'externalStyles';
      csstag.href = settings.styles.external;
      document.head.appendChild(csstag);
    }
    // attach inline styles
    if (settings.styles && settings.styles.inline) {
      this.setState({inlineStyles:settings.styles.inline})
    }
  }

  render(){ 
    const { logoURL,mobileLogoURL,inlineStyles,welcome,disclaimer,selfie,share,siteid,splash } = this.state;
    // only render router if settings have been set.
    return (
      <div className="App" style={ inlineStyles }>
        <Router>
          <Header logoURL={logoURL} mobileLogoURL={mobileLogoURL} />
          {siteid && <Routes>
            <Route exact path="/" element={<Splash settings={splash} />} />
            <Route exact path="/welcome" element={<Welcome wsettings={welcome} dsettings={disclaimer} />} />
            <Route exact path="/question1" element={<Question key={1} settings={settings.question1} />} />
            <Route exact path="/question2" element={<Question key={2} settings={settings.question2} background={settings.background} foreground={settings.foreground} />} />
            <Route exact path="/takepic" element={<Camera settings={selfie} />} />
            <Route exact path="/review" element={<Preview settings={share} />} />
            <Route exact path="/signup/:id" element={<Signup settings={share} />} />
          </Routes>}
        </Router>
      </div>
    );
  }
}

function Header( {logoURL,mobileLogoURL} ){
  const location = useLocation();
  const path = location.pathname;
  // const [display, setDisplay] = useState(
  //   path !== '/takepic' ? true : false
  // );
  return (
    <>
      {path !== '/takepic' && <header className="App-header">
        <img src={logoURL} className="App-logo" alt='logo' />
        {mobileLogoURL && 
          <img src={mobileLogoURL} className="App-logo-mobile" alt="logo" />
        }
      </header>}
    </>
  );
}


export default App;
