import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../scss/Splash.scss';
import { Tween } from 'react-gsap';

export default function Splash({settings}){
    const navigate = useNavigate();
    const [ clicked, setClicked ] = useState(false);

    function handleOnClick(){
        setClicked(true);
        setTimeout( ()=> navigate('/welcome'), 1000 );
    }
    return (
        <div onClick={handleOnClick} className='fullscreen splash'>
            <Tween from={{ scale:0, alpha:0 }} ease="back.out(1.7)">
            <h1>{settings.title}</h1>
            <p>{settings.intro}</p>
            </Tween>
            <button onClick={handleOnClick} className='control-cta light primary'>{settings.cta}</button>
            {settings.backgroundVideo && 
                <video autoPlay loop muted>
                    <source src={settings.backgroundVideo} type="video/mp4" />
                </video>
            }
        </div>
    );
}