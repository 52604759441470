import { useRef, useEffect, useState } from 'react';

export default function Countdown({onComplete}){
    
    const [ count, setCount ] = useState(5);
    
    useInterval(()=>{
        // console.log('tick',count);
        if (count===0){ onComplete(); }
        // if (count>=0){
        //     clearInterval(timer);
        // } else {
            setCount(count-1);
        // }
    }, 1000);

    return (
        <span className="countdown">{count}</span>
    );
}


function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}